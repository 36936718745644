import React, { Component, Fragment } from "react"
import get from "lodash.get"

import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Modal from "@material-ui/core/Modal"

class AdminTicket extends Component {
  state = {
    currentEvent: null,
    currentTicket: null,
    loading: false,
    deleteOpen: false,
  }

  componentDidMount() {
    const { event, type } = this.props
    event.get().then(response => {
      this.setState({ currentEvent: { ...response.data(), id: event.id } })
    })

    type.get().then(response => {
      this.setState({ currentTicket: { ...response.data(), id: type.id } })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.checkedIn !== this.props.checkedIn) {
      this.setState({ loading: false })
    }
  }

  close() {
    this.setState({ deleteOpen: false })
  }
  close = this.close.bind(this)

  render() {
    const { currentEvent, currentTicket, loading, deleteOpen } = this.state
    const { handleCheckIn, handleDelete, checkedIn } = this.props

    return (
      <div className="ticket">
        {currentEvent && currentTicket ? (
          <>
            <div>Ticket Type: {get(currentTicket, "name")}</div>
            <div>Event: {get(currentEvent, "name")}</div>
            <div className="button-wrapper">
              {loading ? (
                <CircularProgress />
              ) : (
                <Fragment>
                  <Button
                    variant="contained"
                    className="pink"
                    onClick={() => {
                      this.setState({ loading: true }, () =>
                        handleCheckIn(!checkedIn)
                      )
                    }}
                  >
                    {checkedIn ? "Check Out" : "Check In"}
                  </Button>

                  <Button
                    variant="contained"
                    className="red"
                    onClick={() => {
                      this.setState({ loading: true, deleteOpen: true })
                    }}
                  >
                    Delete
                  </Button>
                </Fragment>
              )}
            </div>

            <Modal open={deleteOpen} onClose={this.close}>
              <div className="modal-wrapper">
                <div>Are you sure you want to delete this ticket?</div>
                <div style={{ marginTop: "24px" }}>
                  <Button
                    style={{ marginRight: "24px" }}
                    variant="outlined"
                    onClick={() => handleDelete()}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ loading: false, deleteOpen: false })
                    }}
                  >
                    No
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        ) : (
          <CircularProgress />
        )}
      </div>
    )
  }
}

export default AdminTicket
