import React, { PureComponent } from "react"

import AdminTicket from "../components/admin-ticket"
import Done from "@material-ui/icons/Done"
import Clear from "@material-ui/icons/Clear"
import Redeem from "@material-ui/icons/Redeem"
import Payment from "@material-ui/icons/Payment"
import get from "lodash.get"
import firebase from "../utils/firebase"
import { hideTicket, hideFreeOrPaid } from "../utils/filters"

class TicketRow extends PureComponent {
  state = { open: false }

  render() {
    const {
      id,
      customer,
      orderId,
      notified,
      tickets,
      ticketType,
      freeOrPaid,
    } = this.props
    const { open } = this.state

    const currentTickets = tickets.map(ticket => {
      const shouldHideTicket =
        hideTicket(ticketType)(ticket) || hideFreeOrPaid(freeOrPaid)(ticket)

      return {
        ...ticket,
        hide: shouldHideTicket,
      }
    })
    const ticketTotal =
      currentTickets.filter(ticket => !ticket.hide).length || 0

    return (
      <div>
        <div
          onClick={() => this.setState({ open: !this.state.open })}
          key={id}
          className={`tickets ${notified ? "sent" : "pending"}`}
        >
          {orderId ? <Payment /> : <Redeem />}
          <span className="ellipsis name">{customer.name || "No Name"}</span>
          <span className="ellipsis email">{customer.email}</span>
          <span style={{ justifySelf: "center" }}>
            {notified ? <Done /> : <Clear />}
          </span>
          <span style={{ justifySelf: "center" }}>{ticketTotal}</span>
        </div>

        {open ? (
          <div className="admin-tickets">
            <div className="wrapper">
              <div className="inner">
                {(currentTickets || []).map((currentTicket, index) => {
                  // NOTE: We want to hide some tickets so we can preserve the array indexes
                  if (currentTicket.hide) {
                    return null
                  }

                  return (
                    <AdminTicket
                      key={`${currentTicket.event.id}-${currentTicket.type.id}-${index}`}
                      {...currentTicket}
                      handleCheckIn={handleCheckIn(id, index)}
                      handleDelete={handleDelete(id, index)}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

function handleCheckIn(ticketId, ticketIndex) {
  return (currentValue = true) => {
    const ticketDocRef = firebase
      .firestore()
      .collection("tickets")
      .doc(ticketId)

    return firebase
      .firestore()
      .runTransaction(function(currentTransaction) {
        // This code may get re-run multiple times if there are conflicts.
        return currentTransaction.get(ticketDocRef).then(function(ticketDoc) {
          if (!ticketDoc.exists) {
            throw "Sorry, that ticket appears to be invalid. Ask the customer to re-open the ticket from the email they received."
          }

          const data = ticketDoc.data()
          const currentTicket = get(data, `tickets.${ticketIndex}`)

          let updatedTickets = data.tickets
          updatedTickets[ticketIndex] = {
            ...currentTicket,
            checkedIn: currentValue,
            checkInTime: firebase.firestore.Timestamp.fromDate(new Date()),
          }

          currentTransaction.update(ticketDocRef, {
            tickets: updatedTickets,
          })
          return {
            ...data,
            tickets: updatedTickets,
          }
        })
      })
      .catch(error => {
        console.error(error)
      })
  }
}

function handleDelete(ticketId, ticketIndex) {
  return () => {
    const ticketDocRef = firebase
      .firestore()
      .collection("tickets")
      .doc(ticketId)

    return firebase
      .firestore()
      .runTransaction(function(currentTransaction) {
        // This code may get re-run multiple times if there are conflicts.
        return currentTransaction.get(ticketDocRef).then(function(ticketDoc) {
          if (!ticketDoc.exists) {
            throw "Sorry, that ticket appears to be invalid. Ask the customer to re-open the ticket from the email they received."
          }

          const data = ticketDoc.data()
          data.tickets.splice(ticketIndex, 1)

          currentTransaction.update(ticketDocRef, {
            tickets: data.tickets,
          })

          return {
            ...data,
          }
        })
      })
      .catch(error => {
        console.error(error)
      })
  }
}

export default TicketRow
