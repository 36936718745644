import React, { Component } from "react"

import firebase from "../utils/firebase"
import Layout from "../components/layout"
import MailOutline from "@material-ui/icons/MailOutline"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Grid from "@material-ui/core/Grid"
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import withAuth from "../utils/with-auth"

import {
  TICKET_TYPES,
  filterTicket,
  filterFreeOrPaidTicket,
  removeTicket,
  removeFreeOrPaid,
  FREE_PAID,
} from "../utils/filters"

import TicketRow from "../components/list-row"

const byNameAscending = (a, b) => {
  return a.customer.name.localeCompare(b.customer.name)
}

const allOrderIds = [
  369,
  368,
  367,
  366,
  365,
  364,
  363,
  362,
  361,
  360,
  359,
  358,
  357,
  356,
  355,
  354,
  353,
  352,
  351,
  350,
  349,
  348,
  347,
  346,
  345,
  344,
  343,
  342,
  341,
  340,
  339,
  338,
  337,
  336,
  335,
  334,
  333,
  332,
  331,
  330,
  329,
  328,
  327,
  326,
  325,
  324,
  323,
  322,
  321,
  320,
  319,
  318,
  317,
  316,
  315,
  313,
  312,
  310,
  309,
  308,
  307,
  306,
  304,
]

const allOrdersAndQuantities = [
  { orderId: 374, quantity: 1 },
  { orderId: 373, quantity: 3 },
  { orderId: 372, quantity: 2 },
  { orderId: 371, quantity: 1 },
  { orderId: 370, quantity: 1 },
  { orderId: 369, quantity: 1 },
  { orderId: 368, quantity: 2 },
  { orderId: 367, quantity: 2 },
  { orderId: 366, quantity: 1 },
  { orderId: 365, quantity: 3 },
  { orderId: 364, quantity: 1 },
  { orderId: 364, quantity: 2 },
  { orderId: 363, quantity: 2 },
  { orderId: 362, quantity: 1 },
  { orderId: 361, quantity: 2 },
  { orderId: 360, quantity: 1 },
  { orderId: 360, quantity: 1 },
  { orderId: 359, quantity: 5 },
  { orderId: 358, quantity: 1 },
  { orderId: 357, quantity: 1 },
  { orderId: 356, quantity: 1 },
  { orderId: 355, quantity: 5 },
  { orderId: 354, quantity: 4 },
  { orderId: 354, quantity: 10 },
  { orderId: 353, quantity: 2 },
  { orderId: 352, quantity: 2 },
  { orderId: 351, quantity: 1 },
  { orderId: 351, quantity: 1 },
  { orderId: 350, quantity: 1 },
  { orderId: 349, quantity: 2 },
  { orderId: 348, quantity: 1 },
  { orderId: 348, quantity: 1 },
  { orderId: 347, quantity: 1 },
  { orderId: 346, quantity: 1 },
  { orderId: 345, quantity: 2 },
  { orderId: 344, quantity: 2 },
  { orderId: 343, quantity: 2 },
  { orderId: 342, quantity: 1 },
  { orderId: 341, quantity: 2 },
  { orderId: 340, quantity: 1 },
  { orderId: 340, quantity: 2 },
  { orderId: 339, quantity: 2 },
  { orderId: 338, quantity: 3 },
  { orderId: 337, quantity: 1 },
  { orderId: 336, quantity: 4 },
  { orderId: 335, quantity: 2 },
  { orderId: 334, quantity: 2 },
  { orderId: 333, quantity: 1 },
  { orderId: 332, quantity: 2 },
  { orderId: 331, quantity: 1 },
  { orderId: 330, quantity: 1 },
  { orderId: 329, quantity: 2 },
  { orderId: 328, quantity: 2 },
  { orderId: 327, quantity: 3 },
  { orderId: 326, quantity: 2 },
  { orderId: 325, quantity: 3 },
  { orderId: 324, quantity: 1 },
  { orderId: 323, quantity: 1 },
  { orderId: 322, quantity: 2 },
  { orderId: 321, quantity: 1 },
  { orderId: 320, quantity: 6 },
  { orderId: 319, quantity: 2 },
  { orderId: 318, quantity: 2 },
  { orderId: 317, quantity: 2 },
  { orderId: 317, quantity: 2 },
  { orderId: 316, quantity: 1 },
  { orderId: 315, quantity: 2 },
  { orderId: 313, quantity: 2 },
  { orderId: 312, quantity: 2 },
  { orderId: 310, quantity: 1 },
  { orderId: 309, quantity: 2 },
  { orderId: 308, quantity: 2 },
  { orderId: 307, quantity: 2 },
  { orderId: 306, quantity: 1 },
  { orderId: 304, quantity: 2 },
]

@withAuth
class HomePage extends Component {
  state = {
    tickets: [],
    filterBy: "",
    loading: true,
    ticketType: TICKET_TYPES.ALL,
    freeOrPaid: FREE_PAID.ALL,
  }

  componentDidMount() {
    const ticketCollection = firebase.firestore().collection("tickets")

    ticketCollection
      .where("customer.email", ">", "")
      .get()
      .then(snapshot => {
        let tickets = []

        snapshot.forEach(document => {
          tickets.push({ ...document.data(), id: document.id })
          ticketCollection.doc(document.id).onSnapshot(
            {
              includeMetadataChanges: true,
            },
            this.handleTicketUpdate
          )

          // const ticketDocRef = ticketCollection.doc(document.id)

          // firebase.firestore().runTransaction(function(currentTransaction) {
          //   // This code may get re-run multiple times if there are conflicts.
          //   return currentTransaction
          //     .get(ticketDocRef)
          //     .then(function(ticketDoc) {
          //       if (!ticketDoc.exists) {
          //         throw "Sorry, that ticket appears to be invalid. Ask the customer to re-open the ticket from the email they received."
          //       }

          //       const data = ticketDoc.data()

          //       currentTransaction.update(ticketDocRef, {
          //         customer: {
          //           ...data.customer,
          //           email: data.customer.email.trim().toLowerCase(),
          //         },
          //       })
          //     })
          // })
        })

        // Merge tickets by email
        const uniqueTickets = tickets
          .filter(ticketItem => !ticketItem.mergedId)
          .reduce((result, currentTicket) => {
            const possibleMatch = result.findIndex(
              ({ customer }) => customer.email === currentTicket.customer.email
            )

            // If customer is in results update their tickets + add current ID as merged ID
            if (possibleMatch > -1) {
              const oldTicket = result[possibleMatch]
              const newTicket = {
                ...oldTicket,
                mergedIds: [...oldTicket.mergedIds, currentTicket.id],
              }

              result[possibleMatch] = newTicket
              return result
            }

            return [...result, { ...currentTicket, mergedIds: [] }]
          }, [])

        this.setState({
          tickets: tickets
            .filter(ticketItem => !ticketItem.mergedId)
            .sort(byNameAscending),
          loading: false,
        })
      })
  }

  handleTicketUpdate(newDocument) {
    const { tickets } = this.state
    const newTicket = { ...newDocument.data(), id: newDocument.id }
    const toBeReplace = tickets.findIndex(ticket => ticket.id === newTicket.id)

    let newTickets = [...tickets]

    if (toBeReplace !== -1) {
      newTickets[toBeReplace] = newTicket
    } else {
      newTickets = [...newTickets, newTicket]
    }

    this.setState({
      tickets: newTickets
        .filter(ticketItem => !ticketItem.mergedId)
        .sort(byNameAscending),
    })
  }
  handleTicketUpdate = this.handleTicketUpdate.bind(this)

  handleSearch(event) {
    this.setState({ filterBy: event.target.value })
  }
  handleSearch = this.handleSearch.bind(this)

  handleFilter({ customer }) {
    const { filterBy } = this.state
    const lowerFilterBy = filterBy.toLowerCase()
    return (
      customer.name.toLowerCase().includes(lowerFilterBy) ||
      customer.email.toLowerCase().includes(lowerFilterBy)
    )
  }
  handleFilter = this.handleFilter.bind(this)

  updateState(event) {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  updateState = this.updateState.bind(this)

  updateCheckState(event) {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }
  updateCheckState = this.updateCheckState.bind(this)

  render() {
    const { tickets, filterBy, loading, ticketType, freeOrPaid } = this.state

    const filteredTickets = tickets
      .filter(this.handleFilter)
      .filter(filterTicket(ticketType))
      .filter(filterFreeOrPaidTicket(freeOrPaid))

    console.warn('FILTERED TICKETS: ', filteredTickets);

    // console.warn(filteredTickets)
    // .filter(ticket => {
    //   return ticket.tickets.some(ticketItem => {
    //     const { free: isFree = false } = ticketItem
    //     return isFree === free
    //   })
    // })

    // const reducedQuants = allOrdersAndQuantities.reduce(
    //   (result, currentOrder) => {
    //     const possibleMatch = result.findIndex(
    //       current => current.orderId === currentOrder.orderId
    //     )

    //     if (possibleMatch > -1) {
    //       const oldOrder = result[possibleMatch]
    //       const newOrder = {
    //         ...oldOrder,
    //         quantity: oldOrder.quantity + currentOrder.quantity,
    //       }

    //       result[possibleMatch] = newOrder
    //       return result
    //     }

    //     return [...result, currentOrder]
    //   },
    //   []
    // )

    // console.warn(
    //   reducedQuants,
    //   filteredTickets.filter(ticket => !!ticket.orderId)
    // )

    // const misingOrders = reducedQuants.filter(a => {
    //   const currentOrders = filteredTickets
    //     .filter(ticket => !!ticket.orderId)
    //     .map(b => b.orderId)
    //   console.warn("CURRENT: ", currentOrders)
    //   return !currentOrders.includes(`00${a.orderId}`)
    // })

    // const mismatches = reducedQuants.reduce((result, currentQuant) => {
    //   const matchingOrder = filteredTickets.find(
    //     ticket => `00${currentQuant.orderId}` === ticket.orderId
    //   )

    //   if (!!matchingOrder) {
    //     if (matchingOrder.tickets.length !== currentQuant.quantity) {
    //       return [...result, currentQuant]
    //     }
    //   }

    //   return result
    // }, [])

    // console.warn("MISMATCHES: ", mismatches, misingOrders)

    // const filteredOrderIds = filteredTickets.map(t => t.orderId)

    // console.warn(
    //   "EXTRA: ",
    //   filteredOrderIds.filter(orderId => {
    //     return !allOrderIds.map(id => `00${id}`).includes(orderId)
    //   })
    // )
    // console.warn(
    //   "MISSING: ",
    //   allOrderIds.filter(orderId => {
    //     return !filteredOrderIds.includes(`00${orderId}`)
    //   })
    // )

    const totalTickets = tickets.reduce((result, ticket) => {
      return (result += ticket.tickets.filter(removeTicket(ticketType)).length)
    }, 0)
    const totalCustomers = tickets.length
    const fitleredTotalCustomers = filteredTickets.length
    const currentTotalTickets = filteredTickets.reduce((result, ticket) => {
      const filteredLength = ticket.tickets
        .filter(removeTicket(ticketType))
        .filter(removeFreeOrPaid(freeOrPaid)).length
      return (result += filteredLength)
    }, 0)

    return (
      <Layout
        withNav
        isLoading={loading}
        className={"list"}
        style={{ padding: "0 24px 40px", width: "100%" }}
      >
        <div style={{ display: "grid", width: "100%" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div>Search</div>
              <div>
                Tickets: {currentTotalTickets} of {totalTickets} Customers:{" "}
                {fitleredTotalCustomers} of {totalCustomers}
              </div>
            </div>
            <div>
              <input
                style={{ width: "100%" }}
                type="text"
                onChange={this.handleSearch}
                value={filterBy}
              />
            </div>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl style={{ paddingBottom: "24px", width: "100%" }}>
                <InputLabel id="ticketTypeLabel">Ticket Type</InputLabel>
                <Select
                  name="ticketType"
                  value={this.state.ticketType}
                  onChange={this.updateState}
                  defaultValue={TICKET_TYPES.ALL}
                >
                  <MenuItem value={TICKET_TYPES.ALL}>All</MenuItem>
                  <MenuItem value={TICKET_TYPES.GA_STANDING}>
                    GA Standing
                  </MenuItem>
                  <MenuItem value={TICKET_TYPES.GA_SEATED}>GA Seated</MenuItem>
                  <MenuItem value={TICKET_TYPES.VIP}>VIP</MenuItem>
                  <MenuItem value={TICKET_TYPES.LAGOON}>Lagoon Lounge</MenuItem>
                  <MenuItem value={TICKET_TYPES.CONFERENCE}>
                    Conference
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl style={{ paddingBottom: "24px", width: "100%" }}>
                <InputLabel id="freeOrPaidLabel">Free or Paid</InputLabel>
                <Select
                  name="freeOrPaid"
                  value={this.state.freeOrPaid}
                  onChange={this.updateState}
                  defaultValue={FREE_PAID.ALL}
                >
                  <MenuItem value={FREE_PAID.ALL}>All</MenuItem>
                  <MenuItem value={FREE_PAID.FREE}>Free</MenuItem>
                  <MenuItem value={FREE_PAID.PAID}>Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <div className="tickets-header">
            <div
              style={{
                marginRight: "5px",
                marginLeft: "10px",
                width: "24px",
                height: "24px",
              }}
            ></div>
            <div>Name</div>
            <div className="email">Email</div>
            <div style={{ justifySelf: "center" }}>
              <MailOutline />
            </div>
            <div style={{ justifySelf: "center" }}>
              <ConfirmationNumberIcon />
            </div>
          </div>
          <div>
            {filteredTickets.map((ticket, index) => (
              <TicketRow
                {...ticket}
                ticketType={ticketType}
                freeOrPaid={freeOrPaid}
                key={`${ticket.customer.email}-${index}`}
              />
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default HomePage
