const TICKET_TYPES = {
  GA_STANDING: "GxjQHYEscXNpSCOvWGae",
  GA_SEATED: "lTAQjBVLn5Cky1TpCTli",
  VIP: "k6g197m8SdxsfHGqqTjH",
  LAGOON: "U7CZpTB2EJSeD5l3QhM4",
  STANDARD: "nLzwLw0DZPgOP1kNR8s6",
  CONCESSION: "RuijbAa9la3T2WhnvHVd",
  CONFERENCE: "conference",
  ALL: "all",
}

const FREE_PAID = {
  ALL: "all",
  FREE: "free",
  PAID: "paid",
}

const filterTicket = (ticketType: string) => {
  return (ticket: any) => {
    return ticketType === TICKET_TYPES.ALL
      ? true
      : ticket.tickets.some(ticketItem => {
          // TODO: Handle conference type
          if (ticketType === TICKET_TYPES.CONFERENCE) {
            return [TICKET_TYPES.STANDARD, TICKET_TYPES.CONCESSION].includes(
              ticketItem.type.id
            )
          } else {
            return ticketItem.type.id === ticketType
          }
        })
  }
}

const filterFreeOrPaidTicket = (freeOrPaidType: string) => {
  return (ticket: any) => {
    return freeOrPaidType === FREE_PAID.ALL
      ? true
      : ticket.tickets.some(({ free: isFree = false }) => {
          if (freeOrPaidType === FREE_PAID.FREE) {
            return isFree
          }

          if (freeOrPaidType === FREE_PAID.PAID) {
            return !isFree
          }

          return false
        })
  }
}

const removeTicket = (ticketType: string) => {
  return (ticket: any) => {
    if (ticketType === TICKET_TYPES.ALL) {
      return true
    }

    if (ticketType === TICKET_TYPES.CONFERENCE) {
      return [TICKET_TYPES.STANDARD, TICKET_TYPES.CONCESSION].includes(
        ticket.type.id
      )
    }

    return ticket.type.id === ticketType
  }
}

const removeFreeOrPaid = (freeOrPaidType: string) => {
  return ({ free: isFree = false }: any) => {
    if (freeOrPaidType === FREE_PAID.ALL) {
      return true
    }

    if (freeOrPaidType === FREE_PAID.FREE) {
      return isFree
    }

    if (freeOrPaidType === FREE_PAID.PAID) {
      return !isFree
    }

    return false
  }
}

const hideTicket = (ticketType: string) => {
  return (ticket: any) => {
    if (ticketType === TICKET_TYPES.ALL) {
      return false
    }

    if (ticketType === TICKET_TYPES.CONFERENCE) {
      return ![TICKET_TYPES.STANDARD, TICKET_TYPES.CONCESSION].includes(
        ticket.type.id
      )
    }

    return ticket.type.id !== ticketType
  }
}

// SHOULD HIDE?
const hideFreeOrPaid = (freeOrPaidType: string) => {
  return ({ free: isFree = false }: any) => {
    if (freeOrPaidType === FREE_PAID.ALL) {
      return false
    }

    if (freeOrPaidType === FREE_PAID.FREE) {
      return !isFree
    }

    if (freeOrPaidType === FREE_PAID.PAID) {
      return isFree
    }

    return true
  }
}

export {
  TICKET_TYPES,
  FREE_PAID,
  filterTicket,
  filterFreeOrPaidTicket,
  removeTicket,
  removeFreeOrPaid,
  hideTicket,
  hideFreeOrPaid,
}
